export default defineAppConfig({
  ui: {
    primary: 'info',
    gray: 'stone',
    button: {
      rounded: 'rounded-full',
      variant: {
        outline:
          'ring-2 ring-inset ring-current text-light-500 dark:text-{color}-400 hover:bg-{color}-50 disabled:bg-transparent dark:hover:bg-{color}-950 dark:disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-light-500 dark:focus-visible:ring-{color}-400',
      },
    },
    notifications: {
      position: 'bottom-0 inset-x-0',
    },
    progress: {
      step: {
        first: 'text-pink-500 dark:text-pink-400',
      },
    },
  },
})
