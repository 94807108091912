import { default as _91_46_46_46slug_93HxQxjzxhjNMeta } from "/opt/buildhome/repo/pages/[...slug].vue?macro=true";
import { default as add_45themeMcECLrVpXrMeta } from "/opt/buildhome/repo/pages/admin/add-theme.vue?macro=true";
import { default as indexhyMXUmjJA7Meta } from "/opt/buildhome/repo/pages/admin/index.vue?macro=true";
import { default as manasaOEAvVGY5hMeta } from "/opt/buildhome/repo/pages/admin/manas.vue?macro=true";
import { default as admin02XFd05CPxMeta } from "/opt/buildhome/repo/pages/admin.vue?macro=true";
import { default as _91_46_46_46slug_93AFWodWyJfRMeta } from "/opt/buildhome/repo/pages/app/[...slug].vue?macro=true";
import { default as calendrierxzbinl0to0Meta } from "/opt/buildhome/repo/pages/app/calendrier.vue?macro=true";
import { default as indexIbNDbq129sMeta } from "/opt/buildhome/repo/pages/app/index.vue?macro=true";
import { default as cameraGxFio15UzsMeta } from "/opt/buildhome/repo/pages/app/theme/[date]/camera.vue?macro=true";
import { default as indexrEg444kN8wMeta } from "/opt/buildhome/repo/pages/app/theme/[date]/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93HxQxjzxhjNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue")
  },
  {
    name: admin02XFd05CPxMeta?.name,
    path: "/admin",
    meta: admin02XFd05CPxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/admin.vue"),
    children: [
  {
    name: "admin-add-theme",
    path: "add-theme",
    component: () => import("/opt/buildhome/repo/pages/admin/add-theme.vue")
  },
  {
    name: "admin",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/admin/index.vue")
  },
  {
    name: "admin-manas",
    path: "manas",
    component: () => import("/opt/buildhome/repo/pages/admin/manas.vue")
  }
]
  },
  {
    name: "app-slug",
    path: "/app/:slug(.*)*",
    meta: _91_46_46_46slug_93AFWodWyJfRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[...slug].vue")
  },
  {
    name: "app-calendrier",
    path: "/app/calendrier",
    component: () => import("/opt/buildhome/repo/pages/app/calendrier.vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/opt/buildhome/repo/pages/app/index.vue")
  },
  {
    name: "app-theme-date-camera",
    path: "/app/theme/:date()/camera",
    component: () => import("/opt/buildhome/repo/pages/app/theme/[date]/camera.vue")
  },
  {
    name: "app-theme-date",
    path: "/app/theme/:date()",
    component: () => import("/opt/buildhome/repo/pages/app/theme/[date]/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  }
]